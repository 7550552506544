import axios from "axios";
import { BASE_URL, REGULATORY_REPORT_BASE_URL } from "../appConstants";

import { getTokenFromLocalStorage } from "../helpers/localStorage";
import { appInsights } from "../config/appInsights";

const DEVELOPMENT_URL = BASE_URL;
const DEVELOPMENT_REPORT_URL = REGULATORY_REPORT_BASE_URL;
let savedHeaders = {};

const ajaxApi = axios.create({
  baseURL: DEVELOPMENT_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "content-security-policy":
      "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
  },
});

export const ajaxReportApi = axios.create({
  baseURL: DEVELOPMENT_REPORT_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "content-security-policy":
      "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
  },
});

let tokenFromLocalStorage = getTokenFromLocalStorage();

if (tokenFromLocalStorage) {
  ajaxApi.interceptors.request.use(
    function (config) {
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
        Authorization: `Token ${tokenFromLocalStorage}`,
      };

      return config;
    },
    function (error) {
      appInsights.trackException({ exception: error });
      return Promise.reject(error);
    },
  );

  ajaxReportApi.interceptors.request.use(
    function (config) {
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
        Authorization: `Token ${tokenFromLocalStorage}`,
      };

      return config;
    },
    function (error) {
      appInsights.trackException({ exception: error });
      return Promise.reject(error);
    },
  );

  ajaxApi.interceptors.response.use(
    function (config) {
      return config;
    },
    function (error) {
      const { baseURL, headers, method, url } = error?.config;

      if (error.response && error.response.status === 401) {
        window.location.replace("/logout");
      }

      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "axios-register.js",
          baseURL,
          headers,
          method,
          url,
          response: error?.response?.data,
        },
      });
      return Promise.reject(error);
    },
  );

  ajaxReportApi.interceptors.response.use(
    function (config) {
      return config;
    },
    function (error) {
      const { baseURL, headers, method, url } = error?.config;

      if (error.response && error.response.status === 401) {
        window.location.replace("/logout");
      }

      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "axios-register.js",
          baseURL,
          headers,
          method,
          url,
          response: error?.response?.data,
        },
      });
      return Promise.reject(error);
    },
  );
} else {
  ajaxApi.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      appInsights.trackException({ exception: error });
      return Promise.reject(error);
    },
  );

  ajaxApi.interceptors.response.use(
    function (response) {
      const Ssoarraffinity = response.headers["ssoarraffinity"];
      const Ssoarraffinitysamesite = response.headers["ssoarraffinitysamesite"];
      if (Ssoarraffinity && Ssoarraffinitysamesite) {
        savedHeaders = {
          ssoarraffinity: Ssoarraffinity,
          ssoarraffinitysamesite: Ssoarraffinitysamesite,
        };

        localStorage.setItem("savedHeaders", JSON.stringify(savedHeaders));
      }

      return response;
    },
    function (error) {
      appInsights.trackException({ exception: error });
      return Promise.reject(error);
    },
  );

  ajaxApi.interceptors.response.use(
    function (config) {
      return config;
    },
    function (error) {
      const { baseURL, headers, method, url } = error?.config;

      if (error.response && error.response.status === 401) {
        window.location.replace("/logout");
      }

      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "axios-register.js",
          baseURL,
          headers,
          method,
          url,
          response: error?.response?.data,
        },
      });
      return Promise.reject(error);
    },
  );
  // delete ajaxApi.defaults.headers.common["Authorization"];
  // delete ajaxReportApi.defaults.headers.common["Authorization"];
}

export default ajaxApi;
